<template>
	<v-card class="mt-2 mb-5 pb-2" rounded="xl">
		<v-card-title>
			<span>{{ $t('offers.activity') }}</span>
		</v-card-title>
		<v-simple-table fixed-header>
			<table width="100%" cellspacing="0">
				<thead>
					<tr>
						<th class="text-center align-middle">{{ $t('offers.change') }}</th>
						<th class="text-center align-middle">{{ $t('offers.date') }}</th>
						<th class="text-center align-middle">{{ $t('applications.status') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center">
							{{ $t('offers.offerCreated') }}
						</td>
						<td class="text-center">{{ humanTime(offer.timestamp) }}</td>
						<td class="text-center">
							<v-chip v-if="!offer.status.verified.value" small :ripple="false" color="red" class="my-1">
								<v-icon left>mdi-close</v-icon> {{ $t('users.verified') }}
							</v-chip>
						</td>
					</tr>
					<tr v-for="activity in offer.activity" :key="activity.id * activity.id">
						<template v-if="activity.title">
							<td class="text-center">
								{{ $t('offers.change') }} #{{ activity.index }}
								<OfferChange :loading-parent="loading || loadingParent" :change="activity" />
							</td>
							<td class="text-center">{{ humanTime(activity.timestamp) }}</td>
							<td class="text-center">
								<template v-if="!activity.status.pending">
									<v-chip small :ripple="false" :color="statusColor(activity.status)" class="my-1" :outlined="activity.status.canceled">
										<v-icon left>{{ statusIcon(activity.status) }}</v-icon>
										{{ statusText(activity.status) }}
									</v-chip>
								</template>
								<template v-else>
									<v-btn color="green darken-3" small rounded class="mx-2" @click="callApproveChange(activity)">
										<v-icon left>mdi-check</v-icon> {{ $t('offers.approve') }}
									</v-btn>
									<v-btn color="orange darken-3" small rounded class="mx-2" @click="callRefuseChange(activity)">
										<v-icon left>mdi-close</v-icon> {{ $t('offers.refuse') }}
									</v-btn>
									<v-btn color="orange darken-3" small rounded class="mx-2" @click="callCancelChange(activity)">
										<v-icon left>mdi-close</v-icon> {{ $t('offers.cancel') }}
									</v-btn>
								</template>
							</td>
						</template>
						<template v-else>
							<td class="text-center">
								{{ activity.name }}
							</td>
							<td class="text-center">{{ humanTime(activity.timestamp) }}</td>
							<td class="text-center">
								<template v-if="activity.type == 'offer_verified_1'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('users.verified') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_verified_0'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('users.unverified') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_active'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('offers.active') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_blocked'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('offers.blocked') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_solved'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('offers.solved') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_expired'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('offers.expired') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_closed'">
									<v-chip small :ripple="false" color="red" class="my-1"> <v-icon left>mdi-close</v-icon> {{ $t('offers.closed') }} </v-chip>
								</template>
								<template v-else-if="activity.type == 'offer_opened'">
									<v-chip small :ripple="false" color="green" class="my-1"> <v-icon left>mdi-check</v-icon> {{ $t('offers.opened') }} </v-chip>
								</template>
							</td>
						</template>
					</tr>
				</tbody>
			</table>
		</v-simple-table>

		<v-card-actions class="d-flex flex-wrap">
			<v-btn v-if="!offer.status.verified.value" color="green" rounded :loading="loading || loadingParent" @click="callVerifyOffer()">
				{{ $t('auth.verify') }}
			</v-btn>
			<v-btn v-if="!offer.status.blocked.value" color="red" rounded :loading="loading || loadingParent" @click="callBlockOffer()">
				{{ $t('offers.block') }}
			</v-btn>
			<v-btn v-if="!offer.status.active.value" color="green" rounded :loading="loading || loadingParent" @click="callActivateOffer()">
				{{ $t('offers.activate') }}
			</v-btn>
			<v-btn v-if="!offer.status.solved.value" color="green" rounded :loading="loading || loadingParent" @click="callSolveOffer()">
				{{ $t('offers.solve') }}
			</v-btn>
			<template v-if="offer.status.closed.value">
				<v-btn color="green" rounded :loading="loading || loadingParent" @click="callOpenOffer()">
					{{ $t('offers.open') }}
				</v-btn>
			</template>
			<template v-else>
				<v-btn color="red" rounded :loading="loading || loadingParent" @click="callCloseOffer()">
					{{ $t('search.close') }}
				</v-btn>
			</template>
			<v-checkbox v-model="alertApplications" :label="$t('offers.alert')" outlined />
			<v-btn color="primary" rounded :to="{ name: 'OfferCreate', query: { reuse: true } }">
				{{ $t('offers.reuseAsTemplate') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'OfferActivity',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			alertApplications: false
		}
	},
	components: {
		OfferChange: () => import('@/components/offers/OfferChange.vue')
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	},
	created() {},
	methods: {
		statusColor(status) {
			if (status.refused | status.canceled) return 'red'
			else if (status.approved) return 'green'
			else return 'grey'
		},
		statusIcon(status) {
			if (status.refused | status.canceled) return 'mdi-close'
			else if (status.approved) return 'mdi-check'
			else return 'mdi-help-circle-outline'
		},
		statusText(status) {
			if (status.refused) return this.$t('offers.refused')
			else if (status.canceled) return this.$t('offers.canceled')
			else if (status.approved) return this.$t('offers.approved')
			else return this.$t('offers.pending')
		},
		humanTime(timestamp) {
			return this.$moment(timestamp).format('YYYY-MM-DD hh:mm:ss')
		},
		callCancelChange(change) {
			this.loading = true
			this.cancelChange(change.id).then(() => {
				this.loading = false
			})
		},
		callApproveChange(change) {
			this.loading = true
			this.approveChange({ changeID: change.id, alertApplications: this.alertApplications }).then(() => {
				this.loading = false
			})
		},
		callRefuseChange(change) {
			this.loading = true
			this.refuseChange(change.id).then(() => {
				this.loading = false
			})
		},
		callVerifyOffer() {
			this.loading = true
			this.verifyOffer(this.offer.id).then(() => {
				this.loading = false
			})
		},
		callBlockOffer() {
			this.loading = true
			this.blockOffer(this.offer.id).then(() => {
				this.loading = false
			})
		},
		callActivateOffer() {
			this.loading = true
			this.activateOffer(this.offer.id).then(() => {
				this.loading = false
			})
		},
		callSolveOffer() {
			this.loading = true
			this.solveOffer(this.offer.id).then(() => {
				this.loading = false
			})
		},
		callCloseOffer() {
			this.loading = true
			this.closeOffer(this.offer.id).then(() => {
				this.loading = false
			})
		},
		callOpenOffer() {
			this.loading = true
			this.openOffer(this.offer.id).then(() => {
				this.loading = false
			})
		},
		...mapActions('offers', [
			'cancelChange',
			'approveChange',
			'refuseChange',
			'verifyOffer',
			'blockOffer',
			'activateOffer',
			'solveOffer',
			'closeOffer',
			'openOffer'
		])
	}
}
</script>
